const convertJSONToCSV = (jsonData, columnHeaders) => {
  // Check if JSON data is empty
  if (jsonData.length === 0) {
    return '';
  }

  // Create headers string
  const headers = `${columnHeaders.join(',')}\n`;

  // Map JSON data to CSV rows
  const rows = jsonData
    .map(row => {
      // Map each row to CSV format
      return columnHeaders
        .map(field => (row[field]?.includes(', ') ? `"${row[field]}"` : row[field]) || '')
        .join(',');
    })
    .join('\n');

  // Combine headers and rows
  return headers + rows;
};

// Function to initiate CSV download
const downloadCSV = (jsonData, headers, fileName) => {
  const csvData = convertJSONToCSV(jsonData, headers);

  // Check if CSV data is empty
  if (csvData === '') {
    alert('No data to export');
  } else {
    // Create CSV file and initiate download
    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', fileName);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};

export { convertJSONToCSV, downloadCSV };
