import adapter from 'axios/lib/adapters/xhr';
import Axios from 'axios';
import { tokenStore } from './sdkLoader';
import { deserialize, serialize } from './api';

const CLIENT_ID = process.env.REACT_APP_SHARETRIBE_SDK_CLIENT_ID;
const USING_SSL = process.env.REACT_APP_SHARETRIBE_USING_SSL === 'true';

export const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  if (typeof window !== 'undefined') {
    // Otherwise, use the same domain and port as the frontend
    return `${window.location.origin}`;
  }

  return null;
};

const baseURLMaybe = apiBaseUrl() ? { baseURL: apiBaseUrl() } : {};

export const axios = Axios.create({
  adapter,
  ...baseURLMaybe,
});

axios.interceptors.request.use(axiosConfig => {
  const store = tokenStore.browserCookieStore({
    clientId: CLIENT_ID,
    secure: USING_SSL,
  });
  return {
    ...axiosConfig,
    headers: {
      ...axiosConfig.headers,
      AuthToken: JSON.stringify(store.getToken()),
    },
  };
});

const REQUEST_CONFIG = {
  headers: {
    Accept: 'application/transit+json',
    'Content-Type': 'application/transit+json',
  },
  transformResponse: data => deserialize(data),
  transformRequest: data => serialize(data),
};

export const listingUpdateSellerNameApi = (body, params) =>
  axios.post('/api/listings/update-seller-name', body, {
    ...params,
    ...REQUEST_CONFIG,
  });

export const listingConfigApi = query =>
  axios.get('/api/listings/config', {
    params: {
      ...query,
    },
  });

export const updateListingVariant = ({ id, variantFile, shouldUpdateData, listingMode }) => {
  const formData = new FormData();
  formData.append('file', variantFile);
  formData.append('shouldUpdateData', shouldUpdateData);
  formData.append('id', id.uuid);
  formData.append('listingMode', listingMode);
  formData.append(
    'fileName',
    typeof variantFile === 'string' ? variantFile : variantFile.originalname
  );
  return axios.post(`/api/listings/variants`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const updateListingBundle = ({ id, bundleFile, shouldUpdateData }) => {
  const formData = new FormData();
  formData.append('file', bundleFile);
  formData.append('shouldUpdateData', shouldUpdateData);
  formData.append('id', id.uuid);
  formData.append(
    'fileName',
    typeof bundleFile === 'string' ? bundleFile : bundleFile.originalname
  );

  return axios.post(`/api/listings/bundle`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

export const getListingVariants = ({ id }) => {
  return axios.get(`/api/listings/variants`, {
    params: {
      id,
      getName: true,
    },
  });
};

export const getListingVariantsWithPrice = ({ id }) => {
  return axios.get(`/api/listings/variants`, {
    params: {
      id,
    },
  });
};

export const getListingBundle = ({ id }) => {
  return axios.get(`/api/listings/bundle`, {
    params: {
      id,
    },
  });
};
